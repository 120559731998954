a {
    color: inherit;
    text-decoration: none;
    transition: 0.4s ease;
    background-color: transparent;
}

a:hover {
    color:rgba(0,255,255,0.7);
    transition: 0.4s ease;
    outline: 0;
}


nav {
    margin: 2vw 0 0 0;
}

nav a {
    display: inline-block;
    margin: 0 2vw 0 0;
    letter-spacing: 0.5px;
    color: #5a6472;
    font-weight: 400;
    font-size: 2rem;
}