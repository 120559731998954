.projectSection {
    /* padding: 10rem 2rem; */
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
}

.projectDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

/* .projectBoard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
} */

.projectCard {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 900px;
    margin: 4rem auto;
    margin-bottom: 15rem;
}

.projectCard:nth-child(even) {
    flex-direction: row-reverse;
}

.projectCard .left {
    flex: 1;
}

.projectCard .right {
    flex: 1;
    margin-left: -120px;
    background-color: #2d2d38;
    padding: 5rem 2rem;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0,255,255,0.7);
    z-index: 2;
    line-height: 1.6;
}

.projectCard:nth-child(even) .right {
    margin-left: 0;
}

.projectCard:nth-child(even) .left {
    margin-left: -120px;
}

.projectCard .left .img {
    width: 100%;
    /* height: 300px; */
    overflow: hidden;
    border-radius: 12px;
    position: relative;
}

.projectCard .left img::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: white;
}

.projectCard .left img {
    object-fit: cover;
    transition: .3s ease transform;
}

.projectCard:hover .left .img {
    transform: scale(1.1);
    z-index: 1;
}

.projectCard .button {
    display: inline-block;
    background-color: #0f1114;
    padding: 10px;
    width: 100px;
    color: #ffffff;
    text-align: center;
    border: 4px #cccccc;
    border-radius: 10px;
    font-size: 28px;
    cursor: pointer;
    margin: 5px; 
}

@media only screen and (max-width: 768px) {

    h2 {
        font-size: 2rem;
    }

    .projectCard .button {
        font-size: 1.5rem;
        width: 80px;
    }

    .projectCard {
        flex-direction: column;
    }

    .projectCard .left {
        width: 80%;
    }
    .projectCard .right {
        width: 90%;
        margin-left: 0;
        margin-top: -120px;
    }
    .projectCard:nth-child(even) {
        flex-direction: column;
    }
    .projectCard:nth-child(even) .left {
        margin-left: 0;
    }

}

@media only screen and (max-width: 400px) {

    .projectCard .left {
        width: 95%;
    }
    .projectCard .right {
        width: 90%;
        margin-left: 0;
        margin-top: -60px;
    }
}