@import url('https://fonts.googleapis.com/css2?family=Cabin:ital@0;1&display=swap');

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: Cabin -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;
    background: #0f1114;
    color: white;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    scroll-behavior: smooth;
}

::selection {
    text-shadow: none;
    background: rgba(0,255,255,0.7);
}

#container {
    height:100vh;
}

h1 {
    font-size: 10rem;
    font-weight: 700;
    line-height: 1.05em;
    color: inherit;
}

h2 {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 1.05em;
}

h1, h2{
    margin: 0;
    color: #fff;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

p {
    font-size: 2rem;
}

.intro {
    max-width: 1000px;
    overflow: visible;
    height: 100%;
}

section {
    padding: 2vmin 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

h1:not(.intro h1) {
    text-align: center;
}

@media (max-width: 1000px) {
    h1 {
        font-size: 8rem;
    }
}

@media (max-width: 768px) {
    nav {
        display: flex;
        flex-direction: column;
    }
    nav a {
        margin: 10px 0 0 0;
    }
    h1 {
        font-size: 6rem;
    }
    p {
        font-size: 1.5rem;
    }
    .projectCard button {
        font-size: 1.5rem;

    }

}


/* @media (max-width: 400px){
    h1 {
        font-size: 5rem;
    }
} */

